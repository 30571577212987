
@media (((min-width:600px) and (max-width: 768px))) {
    .my_property {
        display: flex !important; 
    }
    .options {
        display: none !important;
    }
    .view_property {
        font-size: 10px !important;
        padding: 0 0.25rem !important;
    }
}

th, td {
    @apply px-7 text-sm text-left text-nowrap
  }
  /* tbody {
    @apply bg-[#f1f1f1];
  } */
   tr {
    @apply pb-20 bg-[#f1f1f1];
   }
  td {
    @apply py-3 border-0;
  }
  th {
    @apply font-semibold py-5;
  }