@import url('https://fonts.cdnfonts.com/css/graphik');
* {
  /* outline: 1px solid silver; */
  font-family: 'Graphik', sans-serif;
}



@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color:; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rm-drag {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

/* Custom file  input */
input[type="file"] {
  display: none;
}

.custom-file-upload {
  border-bottom: 1.5px solid #0FA958;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

/* .active {
  color: #0FA958;
  background-color: red !important;
} */